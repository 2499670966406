import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { TooltipComponent, GridComponent, VisualMapComponent, DataZoomComponent } from 'echarts/components';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

import { getColor } from '../../utils/colors';

// Register the required components
echarts.use([
    TooltipComponent,
    GridComponent,
    VisualMapComponent,
    DataZoomComponent,
    LineChart,
    CanvasRenderer,
    UniversalTransition
]);

// Generate random data
// This is just for the placeholder
const n = 50;
const randomOracleData = [];
for (let i = 0; i < n; i++)
    randomOracleData.push([i, 20 * Math.atan(1000 * (i/n - 0.75)) + 50 + Math.random() * 5]);

const randomPriceData = [];
for (let i = 0; i < n; i++)
    randomPriceData.push([i, 2000 * Math.atan(10 * (i/n - 0.85)) + 29000 + Math.random() * 2000 + 1000 * Math.sin(10 * i/n)]);

function BarometerHistoryChartPlaceholder(props) {
    return (
        <div className="opacity-50 pointer-events-none h-full">
            <ReactEChartsCore
                className="animate-pulse"
                echarts={echarts}
                style={{ height: '100%' }}
                option={{
                    animation: false,
                    grid: {
                        top: 40,
                        left: 40,
                        right: 55,
                        bottom: 30
                    },
                    responsive: true,
                    visualMap: {
                        type: 'continuous',
                        show: false,
                        inRange: {
                            color: [
                                getColor('neutral-softBg'),
                                getColor('text-tertiary'),
                            ],
                        },
                        min: 0,
                        max: 100,
                        seriesIndex: 0
                    },
                    xAxis: {
                        axisLabel: {
                            formatter: ''
                        },
                        type: 'category'
                    },
                    yAxis: [
                        {
                            name: 'Confidence (%)',
                            nameTextStyle: {
                                align: 'left'
                            },
                            splitLine: {
                                lineStyle: {
                                    color: getColor('background-level2')
                                }
                            },
                            axisLabel: {
                                formatter: ''
                            },
                            type: 'value',
                            min: (value) => Math.floor(value.min / 10) * 10 - 10,
                            max: (value) => Math.ceil(value.max / 10) * 10 + 10
                        },
                        {
                            name: 'BTC/USDT',
                            nameTextStyle: {
                                align: 'right'
                            },
                            splitLine: {
                                lineStyle: {
                                    color: getColor('background-level2')
                                }
                            },
                            axisLabel: {
                                formatter: ''
                            },
                            alignTicks: true,
                            type: 'value',
                            min: (value) => Math.floor(value.min / 1000) * 1000 - 1000,
                            max: (value) => Math.ceil(value.max / 1000) * 1000 + 1000,
                        }
                    ],
                    series: [
                        {
                            name: 'Confidence',
                            type: 'line',
                            emphasis: {
                                focus: 'series'
                            },
                            lineStyle: {
                                width: 2
                            },
                            showSymbol: false,
                            data: randomOracleData
                        },
                        {
                            name: 'BTC/USDT',
                            type: 'line',
                            color: getColor('neutral-softActiveBg'),
                            yAxisIndex: 1,
                            emphasis: {
                                focus: 'series'
                            },
                            lineStyle: {
                                width: 2
                            },
                            showSymbol: false,
                            data: randomPriceData
                        }
                    ]
                }} />
        </div>
    );
}


function BarometerHistoryChart(props) {
    // Show loading indicator if data is not available
    if (props.value.last_oracle_indicator === undefined) {
        return (<BarometerHistoryChartPlaceholder />);
    }

    // Show barometer chart if data is available
    return (
        <ReactEChartsCore
            echarts={echarts}
            style={{ height: '100%' }}
            option={{
                grid: {
                    top: 40,
                    left: 40,
                    right: 55,
                    bottom: 30
                },
                responsive: true,
                visualMap: {
                    type: 'continuous',
                    show: false,
                    inRange: {
                        color: [
                            "#fb4667",
                            "#fb4667",
                            "#fb4667",
                            "#fb4667",
                            "#fb4667",
                            "#fb4667",
                            "#fb4667",
                            "#fb4667",
                            "#fb4667",
                            "#fb4667",
                            "#fb4667",
                            "#fb4667",
                            "#fb4667",
                            "#fb4667",
                            "#fb4667",
                            "#fb4667",
                            "rgb(231, 74, 92)",
                            "rgb(232, 83, 90)",
                            "rgb(234, 93, 87)",
                            "rgb(236, 103, 85)",
                            "rgb(237, 112, 83)",
                            "rgb(239, 122, 80)",
                            "rgb(240, 132, 78)",
                            "rgb(242, 142, 76)",
                            "rgb(244, 151, 74)",
                            "rgb(245, 161, 72)",
                            "rgb(247, 171, 69)",
                            "rgb(248, 180, 67)",
                            "rgb(250, 190, 65)",
                            "rgb(252, 200, 62)",
                            "rgb(253, 209, 60)",
                            "rgb(255, 219, 58)",
                            "rgb(242, 218, 58)",
                            "rgb(229, 217, 57)",
                            "rgb(217, 216, 56)",
                            "rgb(204, 216, 56)",
                            "rgb(191, 215, 56)",
                            "rgb(178, 214, 55)",
                            "rgb(165, 213, 54)",
                            "rgb(152, 212, 54)",
                            "rgb(140, 211, 54)",
                            "rgb(127, 210, 53)",
                            "rgb(114, 209, 52)",
                            "rgb(101, 208, 52)",
                            "rgb(88, 208, 52)",
                            "rgb(76, 207, 51)",
                            "rgb(63, 206, 50)",
                            "rgb(50, 205, 50)"
                        ],
                    },
                    min: 0,
                    max: 100,
                    seriesIndex: 0
                },
                tooltip: {
                    trigger: 'axis',
                    formatter: (params) => {
                        const date = new Date(params[0].data[0]);
                        const confidence = params[0].data[1];
                        const confidenceColor = params[0].color;
                        const price = params[1].data[1];
                        const priceColor = params[1].color;
                        return `
                            <div class="flex flex-col text-sm">
                                <div class="font-semibold mb-1">${date.toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                })}</div>
                                <div class="flex flex-row justify-between">
                                    <div>Confidence: </div>
                                    <div class="font-semibold ml-1" style="color: ${confidenceColor}">${confidence.toFixed(2)}%</div>
                                </div>
                                <div class="flex flex-row justify-between">
                                    <div>BTC/USDT: </div>
                                    <div class="font-semibold ml-1" style="color: ${priceColor}">$${price.toFixed(2)}
                                </div>
                            </div>
                        `;
                    },
                    backgroundColor: getColor('background-surface'),
                    borderWidth: 0,
                    textStyle: {
                        color: getColor('text-primary')
                    },
                    shadowBlur: 5,
                    axisPointer: {
                        type: 'cross',
                        animation: false,
                        label: {
                            backgroundColor: getColor('background-level3'),
                            textStyle: {
                                color: getColor('text-primary'),
                                fontWeight: 'bold'
                            }
                        }
                    }
                },
                xAxis: {
                    type: 'time'
                },
                yAxis: [
                    {
                        name: 'Confidence (%)',
                        nameTextStyle: {
                            align: 'left'
                        },
                        splitLine: {
                            lineStyle: {
                                color: getColor('background-level2')
                            }
                        },
                        axisLabel: {
                            formatter: '{value}%'
                        },
                        type: 'value',
                        min: (value) => Math.floor(value.min / 5 - 0.5) * 5,
                        max: (value) => Math.ceil(value.max / 5 + 0.5) * 5,
                    },
                    {
                        name: 'BTC/USDT',
                        nameTextStyle: {
                            align: 'right'
                        },
                        splitLine: {
                            lineStyle: {
                                color: getColor('background-level2')
                            }
                        },
                        axisLabel: {
                            // eslint-disable-next-line no-template-curly-in-string
                            formatter: "${value}"
                        },
                        alignTicks: true,
                        type: 'value',
                        min: (value) => Math.floor(value.min / 500 - 0.5) * 500,
                        max: (value) => Math.ceil(value.max / 500 + 0.5) * 500,
                    }
                ],
                series: [
                    {
                        name: 'Confidence',
                        type: 'line',
                        emphasis: {
                            focus: 'series'
                        },
                        lineStyle: {
                            width: 2
                        },
                        showSymbol: false,                        
                        data: props.value.oracle_indicator.confidence.map(
                            (item, i) => [
                                new Date(props.value.oracle_indicator.timestamps[i] * 1000),
                                Math.round(1000 * item) / 10
                            ]
                        )
                    },
                    {
                        name: 'BTC/USDT',
                        type: 'line',
                        color: getColor('text-secondary'),
                        yAxisIndex: 1,
                        emphasis: {
                            focus: 'series'
                        },
                        lineStyle: {
                            width: 2
                        },
                        showSymbol: false,
                        data: props.value.prices.prices.map(
                            (item, i) => [
                                new Date(props.value.prices.timestamps[i] * 1000),
                                item
                            ]
                        )
                    }
                ]
            }} />
    );
}

export default BarometerHistoryChart;