import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { LineChart } from 'echarts/charts';
import { TooltipComponent, GridComponent, VisualMapComponent, DataZoomComponent } from 'echarts/components';
import { UniversalTransition } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

import { getColor } from '../../utils/colors';

// Register the required components
echarts.use([
    TooltipComponent,
    GridComponent,
    VisualMapComponent,
    DataZoomComponent,
    LineChart,
    CanvasRenderer,
    UniversalTransition
]);


// Generate random data
// This is just for the placeholder
const n = 50;
const greenRandomData = [];
for (let i = 0; i < n; i++) 
    greenRandomData.push([i, 20 * Math.atan(1000 * (i/n - 0.75)) + 45 + Math.random() * 5]);

const neutralRandomData = [];
for (let i = 0; i < n; i++) 
    neutralRandomData.push([i, 10 * Math.atan(1000 * (0.75 - i/n)) + 25 + Math.random() * 5]);

const redRandomData = [];
for (let i = 0; i < n; i++)
    redRandomData.push([i, 100 - greenRandomData[i][1] - neutralRandomData[i][1]]);


function BreakdownHistoryChartPlaceholder(props) {
    return (
        <div className="opacity-50 pointer-events-none h-full">
            <ReactEChartsCore
                className="animate-pulse"
                echarts={echarts}
                style={{ height: '100%' }}
                option={{
                    animation: false,
                    grid: {
                        top: 15,
                        left: 40,
                        right: 10,
                        bottom: 30
                    },
                    responsive: true,
                    xAxis: {
                        type: 'time',
                        axisLabel: {
                            formatter: ''
                        },
                    },
                    yAxis: {
                        type: 'value',
                        min: 0,
                        max: 100,
                        splitLine: {
                            lineStyle: {
                                color: getColor('background-level2')
                            }
                        },
                        axisLabel: {
                            formatter: ''
                        },
                    },
                    series: [
                        {
                            name: 'Green Rates',
                            type: 'line',
                            stack: 'Total',
                            areaStyle: {},
                            emphasis: {
                                focus: 'series'
                            },
                            showSymbol: false,
                            data: greenRandomData,
                            color: getColor('neutral-softActiveBg')
                        },
                        {
                            name: 'Neutral Rates',
                            type: 'line',
                            stack: 'Total',
                            areaStyle: {},
                            emphasis: {
                                focus: 'series'
                            },
                            showSymbol: false,
                            data: neutralRandomData,
                            color: getColor('background-level3')
                        },
                        {
                            name: 'Red Rates',
                            type: 'line',
                            stack: 'Total',
                            areaStyle: {},
                            emphasis: {
                                focus: 'series'
                            },
                            showSymbol: false,
                            data: redRandomData,
                            color: getColor('background-level2')
                        }
                    ]
                }} />
        </div>
    );
}


function BreakdownHistoryChart(props) {
    // Show loading indicator if data is not available
    if (props.value.last_oracle_indicator === undefined) {
        return (<BreakdownHistoryChartPlaceholder />);
    }


    // Calculate total rates
    const total_rates = props.value.oracle_indicator.green_rates_count.map(
        (item, i) => item + props.value.oracle_indicator.neutral_rates_count[i] + props.value.oracle_indicator.red_rates_count[i]
    );

    // Show barometer chart if data is available
    return (
        <ReactEChartsCore
            echarts={echarts}
            style={{ height: '100%' }}
            option={{
                grid: {
                    top: 15,
                    left: 40,
                    right: 10,
                    bottom: 30
                },
                responsive: true,
                tooltip: {
                    trigger: 'axis',
                    formatter: (params) => {
                        const date = new Date(params[0].data[0]);

                        const greenRates = params[0].data[1];
                        const greenRateColor = params[0].color;

                        const neutralRates = params[1].data[1];
                        const neutralRateColor = params[1].color;

                        const redRates = params[2].data[1];
                        const redRateColor = params[2].color;

                        return `
                            <div class="flex flex-col text-sm">
                                <div class="font-semibold mb-1">${date.toLocaleDateString('en-US', {
                                    year: 'numeric',
                                    month: 'long',
                                    day: 'numeric',
                                    hour: 'numeric',
                                    minute: 'numeric',
                                })}</div>
                                <div class="flex flex-row justify-between">
                                    <div>Green rates: </div>
                                    <div class="font-semibold" style="color: ${greenRateColor}">${greenRates.toFixed(1)}%</div>
                                </div>
                                <div class="flex flex-row justify-between">
                                    <div>Neutral rates: </div>
                                    <div class="font-semibold" style="color: ${neutralRateColor}">${neutralRates.toFixed(1)}%</div>
                                </div>
                                <div class="flex flex-row justify-between">
                                    <div>Red rates: </div>
                                    <div class="font-semibold" style="color: ${redRateColor}">${redRates.toFixed(1)}%</div>
                                </div>
                            </div>
                        `;
                    },
                    backgroundColor: getColor('background-surface'),
                    borderWidth: 0,
                    textStyle: {
                        color: getColor('text-primary')
                    },
                    shadowBlur: 5,
                    axisPointer: {
                        type: 'cross',
                        animation: false,
                        label: {
                            backgroundColor: getColor('background-level3'),
                            textStyle: {
                                color: getColor('text-primary'),
                                fontWeight: 'bold'
                            }
                        }
                    }
                },
                xAxis: {
                    type: 'time',
                },
                yAxis: {
                    type: 'value',
                    min: 0,
                    max: 100,
                    splitLine: {
                        lineStyle: {
                            color: getColor('background-level2')
                        }
                    },
                    axisLabel: {
                        formatter: "{value}%"
                    }
                },
                series: [
                    {
                        name: 'Green Rates',
                        type: 'line',
                        stack: 'Total',
                        areaStyle: {},
                        emphasis: {
                            focus: 'series'
                        },
                        showSymbol: false,
                        data: props.value.oracle_indicator.green_rates_count.map(
                            (item, i) => [
                                new Date(props.value.oracle_indicator.timestamps[i] * 1000),
                                Math.round(1000 * item / total_rates[i]) / 10
                            ]
                        ),
                        color: 'limegreen'
                    },
                    {
                        name: 'Neutral Rates',
                        type: 'line',
                        stack: 'Total',
                        areaStyle: {},
                        emphasis: {
                            focus: 'series'
                        },
                        showSymbol: false,
                        data: props.value.oracle_indicator.neutral_rates_count.map(
                            (item, i) => [
                                new Date(props.value.oracle_indicator.timestamps[i] * 1000),
                                Math.round(1000 * item / total_rates[i]) / 10
                            ]
                        ),
                        color: '#ffdb3a'
                    },
                    {
                        name: 'Red Rates',
                        type: 'line',
                        stack: 'Total',
                        areaStyle: {},
                        emphasis: {
                            focus: 'series'
                        },
                        showSymbol: false,
                        data: props.value.oracle_indicator.red_rates_count.map(
                            (item, i) => [
                                new Date(props.value.oracle_indicator.timestamps[i] * 1000),
                                Math.round(1000 * item / total_rates[i]) / 10
                            ]
                        ),
                        color: '#e5405e'
                    }
                ]
            }} />
    );
}

export default BreakdownHistoryChart;