import { useCallback, useEffect, useState } from 'react';
import { HiOutlineArrowPath } from 'react-icons/hi2';

import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import Grid from '@mui/joy/Grid';
import Card from '@mui/joy/Card';
import Box from '@mui/joy/Box';

import Title from '../components/title';

import BarometerChart from '../components/charts/barometer-chart';
import BreakdownChart from '../components/charts/breakdown-chart';

import BarometerHistoryChart from '../components/charts/barometer-history-chart';
import BreakdownHistoryChart from '../components/charts/breakdown-history-chart';
import Typography from '@mui/joy/Typography';
import ButtonGroup from '../components/button-group';


/**
 * Function to convert timestamp to 'time ago' string
 * @param {Date} timeStamp 
 * @returns String
 */
function timeSince(timeStamp) {
    let now = new Date();
    let secondsPast = (now.getTime() - timeStamp.getTime()) / 1000;

    if (secondsPast < 60)
        return 'a few seconds ago';

    if (secondsPast < 3600)
        return parseInt(secondsPast / 60) + 'm ago';

    if (secondsPast <= 86400)
        return parseInt(secondsPast / 3600) + 'h ago';

    if (secondsPast > 86400) {
        let day = timeStamp.getDate();
        let month = timeStamp.toDateString().match(/ [a-zA-Z]*/)[0].replace(" ", "");
        let year = timeStamp.getFullYear() === now.getFullYear() ? "" : " " + timeStamp.getFullYear();
        return day + " " + month + year;
    }
}
// List of periods
const periods = [
    { name: '8h', value: '8h' },
    { name: '1d', value: '1d' },
    { name: '3d', value: '3d' },
    { name: '1w', value: '1w' },
    { name: '1m', value: '1m' },
    { name: '1y', value: '1y' },
    { name: 'all', value: 'all' },
];

function DashboardPage(props) {
    // Set selected period
    const [selectedPeriod, setSelectedPeriod] = useState(periods[0]);

    // Set loading state
    const [loading, setLoading] = useState(true);

    // Function to fetch data from API
    const getData = useCallback(() => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_API_LINK}/home?period=` + selectedPeriod.value)
            .then(res => res.json())
            .then(data => setData(data))
            .then(() => setLoading(false));
    }, [selectedPeriod]);

    // Fetch data from API
    const [data, setData] = useState({});
    useEffect(getData, [selectedPeriod, getData]);

    // Update data every 30 seconds
    useEffect(() => {
        const interval = setInterval(getData, 30000);
        return () => clearInterval(interval);
    }, [selectedPeriod, getData]);


    return (
        <>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mb: 4 }}
            >
                <Title>Dashboard</Title>
                <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                >
                    <Typography level="body1" fontSize="sm" sx={{ m: 0 }}>
                        {loading ? "" : timeSince(new Date(data.last_oracle_indicator.timestamp * 1000))}
                    </Typography>
                    <Button
                        variant="outlined"
                        color="neutral"
                        startDecorator={<HiOutlineArrowPath className="w-5 h-5" />}
                        sx={{
                            backgroundColor: 'background.body',
                        }}
                        loading={loading}
                        onClick={getData}
                    >
                        Refresh
                    </Button>
                    <ButtonGroup
                        options={periods}
                        selectedOption={selectedPeriod.value}
                        onChange={setSelectedPeriod}    
                    />
                </Stack>
            </Stack>

            <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>

                <Box
                    gridColumn={{
                        xs: 'span 6',
                        lg: 'span 4',
                    }}
                >
                    <Card sx={{ height: "100%" }}>
                        <Typography level="h2" fontSize="md" sx={{ mb: 0.5 }}>
                            Confidence
                        </Typography>
                        <BarometerChart name="Confidence" value={data} />
                    </Card>
                </Box>
                <Box
                    gridColumn={{
                        xs: 'span 6',
                        lg: 'span 4',
                    }}
                >
                    <Card sx={{ height: "100%" }}>
                        <Typography level="h2" fontSize="md" sx={{ mb: 0.5 }}>
                            Breakdown
                        </Typography>
                        <BreakdownChart name="Breakdown" value={data} />
                    </Card>
                </Box>
                <Box
                    gridRow={{ lg: '1' }}
                    gridColumn={{
                        xs: 'span 12',
                        lg: '5 / span 8',
                    }}
                >
                    <Card sx={{ height: "100%" }}>
                        <Typography level="h2" fontSize="md" sx={{ mb: 0.5 }}>
                            Confidence History
                        </Typography>
                        <BarometerHistoryChart name="Confidence History" value={data} />
                    </Card>
                </Box>
                <Box
                    gridRow={{ lg: '2' }}
                    gridColumn={{
                        xs: 'span 12',
                        lg: '1 / span 8',
                    }}
                >
                    <Card sx={{ height: "100%" }}>
                        <Typography level="h2" fontSize="md" sx={{ mb: 0.5 }}>
                            Breakdown History
                        </Typography>
                        <BreakdownHistoryChart name="Breakdown History" value={data} />
                    </Card>
                </Box>


            </Box>
        </>
    );

    return (
        <>
            <div className="flex justify-between items-center">
                {/* <Title>Dashboard</Title> */}
                <div className="flex items-center gap-2">
                    <span className="text-slate-600 dark:text-slate-400 text-sm mx-2">
                        {
                            loading
                                ? ''
                                : timeSince(
                                    new Date(data.last_oracle_indicator.timestamp * 1000)
                                )
                        }
                    </span>
                    <button className="flex gap-1 bg-white rounded-lg shadow-md shadow-slate-200 dark:bg-slate-500 dark:bg-opacity-10 dark:shadow-none p-2 text-xs font-bold font-display text-slate-500 hover:text-slate-800 dark:hover:text-slate-100" onClick={getData}>
                        <div className={loading ? 'animate-spin' : ''}>
                            <HiOutlineArrowPath className="w-5 h-5" />
                        </div>
                    </button>
                    <div className="flex gap-1 bg-white rounded-lg shadow-md shadow-slate-200 dark:bg-slate-500 dark:bg-opacity-10 dark:shadow-none p-1 text-xs font-bold font-display">
                        {periods.map(period =>
                            <span
                                key={period}
                                className={
                                    'cursor-pointer px-3 py-1.5 rounded-md ' +
                                    (period === selectedPeriod ? 'bg-indigo-500 bg-opacity-25 text-indigo-500 dark:text-white' : 'text-slate-500 bg-transparent hover:bg-indigo-50 hover:text-indigo-500 dark:hover:bg-indigo-500 dark:hover:bg-opacity-10 dark:hover:text-white')
                                }
                                onClick={() => setSelectedPeriod(period)}
                            >
                                {period}
                            </span>
                        )}
                    </div>
                </div>
            </div>

            <div className="grid grid-cols-2 xl:grid-cols-3 gap-6 mt-8 transition-all">
                <Card>
                    <Card.Title>Confidence</Card.Title>
                    <BarometerChart name="Confidence" value={data} />
                </Card>

                <Card>
                    <Card.Title>Rate Breakdown</Card.Title>
                    <BreakdownChart name="Breakdown" value={data} />
                </Card>

                <Card className="col-span-2 xl:row-start-1 xl:col-start-2 h-96">
                    <Card.Title>Confidence vs. BTC/USDT</Card.Title>
                    <BarometerHistoryChart value={data} />
                </Card>

                <Card className="col-span-2 xl:row-start-2 xl:col-start-1 h-96">
                    <Card.Title>Rate Breakdown History</Card.Title>
                    <BreakdownHistoryChart value={data} />
                </Card>

            </div>

        </>
    );
}

export default DashboardPage;