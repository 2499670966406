import Stack from "@mui/joy/Stack";
import Button from "@mui/joy/Button";


function ButtonGroup(props) {
    const { options, selectedOption, onChange } = props;

    return (
        <Stack
            direction="row"
            gap={0}
        >
            {options.map(option => {
                const { name, value } = option;
                const active = selectedOption === value;

                return (
                    <Button
                        key={value}
                        variant={active ? 'soft' : 'outlined'}
                        color={active ? 'primary' : 'neutral'}
                        sx={{
                            fontWeight: 'bold',
                            backgroundColor: active ? 'primary.main' : 'background.body',

                            borderWidth: '0',
                            borderBlockWidth: '1px',
                            borderStyle: 'solid',
                            borderRadius: '0',
                            boxShadow: active ? '1px 0 0 currentColor inset, -1px 0 0 currentColor inset' : 'none',

                            '&:first-child': {
                                borderTopLeftRadius: '0.5rem',
                                borderBottomLeftRadius: '0.5rem',
                                borderLeftWidth: '1px',
                                boxShadow: active ? '-1px 0 0 currentColor inset' : 'none',
                            },
                            '&:last-child': {
                                borderTopRightRadius: '0.5rem',
                                borderBottomRightRadius: '0.5rem',
                                borderRightWidth: '1px',
                                boxShadow: active ? '1px 0 0 currentColor inset' : 'none',
                            }
                        }}
                        onClick={() => onChange(option)}
                    >
                        {name}
                    </Button>
                );
            })}
        </Stack>
    );
}

export default ButtonGroup;