import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { GaugeChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';

import { getColor } from '../../utils/colors';

// Register the required components
echarts.use(
    [GaugeChart, CanvasRenderer]
);


function BarometerChartPlaceholder(props) {
    // Render the chart placeholder
    return (
        <div className="opacity-50 pointer-events-none">
            <ReactEChartsCore
                className="animate-pulse"
                echarts={echarts}
                option={{
                    series: [{
                        animation: false,
                        name: props.name,
                        type: 'gauge',
                        radius: '90%',
                        startAngle: 220,
                        endAngle: -40,
                        min: 0,
                        max: 100,
                        splitNumber: 10,
                        progress: {
                            show: false,
                            roundCap: true
                        },
                        axisLine: {
                            roundCap: true,
                            lineStyle: {
                                width: 16,
                                color: [
                                    [1, getColor('neutral-softDisabledColor')]
                                ]
                            }
                        },
                        pointer: {
                            icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
                            length: '70%',
                            width: 12,
                            offsetCenter: [0, '10%'],
                            itemStyle: {
                                color: getColor('neutral-softDisabledColor')
                            }
                        },
                        axisLabel: {
                            distance: 22,
                            fontSize: 10,
                            color: getColor('neutral-softDisabledColor'),
                        },
                        detail: {
                            formatter: '--.-%',
                            fontSize: 32,
                            offsetCenter: [0, '75%'],
                            color: getColor('neutral-softDisabledColor'),
                        },
                        title: {
                            fontSize: 14,
                            offsetCenter: [0, '50%'],
                            color: getColor('neutral-softDisabledColor'),
                        },
                        data: [{
                            name: props.name,
                            value: 0,
                        }]
                    }]
                }}
            />
        </div>
    );
}


function BarometerChart(props) {
    // Show loading indicator if data is not available
    if (props.value.last_oracle_indicator === undefined) {
        return (<BarometerChartPlaceholder name={props.name} />);
    }

    // Show barometer chart if data is available
    return (
        <ReactEChartsCore
            echarts={echarts}
            option={{
                series: [{
                    name: props.name,
                    type: 'gauge',
                    radius: '90%',
                    startAngle: 220,
                    endAngle: -40,
                    min: 0,
                    max: 100,
                    splitNumber: 10,
                    progress: {
                        show: false,
                        roundCap: true
                    },
                    axisLine: {
                        roundCap: true,
                        lineStyle: {
                            width: 16,
                            color: [
                                [0, "#fb4667"],
                                [0.3509, "rgb(231, 74, 92)"],
                                [0.3719, "rgb(232, 83, 90)"],
                                [0.3928, "rgb(234, 93, 87)"],
                                [0.4138, "rgb(236, 103, 85)"],
                                [0.4347, "rgb(237, 112, 83)"],
                                [0.4556, "rgb(239, 122, 80)"],
                                [0.4766, "rgb(240, 132, 78)"],
                                [0.4975, "rgb(242, 142, 76)"],
                                [0.5184, "rgb(244, 151, 74)"],
                                [0.5394, "rgb(245, 161, 72)"],
                                [0.5603, "rgb(247, 171, 69)"],
                                [0.5813, "rgb(248, 180, 67)"],
                                [0.6022, "rgb(250, 190, 65)"],
                                [0.6231, "rgb(252, 200, 62)"],
                                [0.6441, "rgb(253, 209, 60)"],
                                [0.6650, "rgb(255, 219, 58)"],
                                [0.6859, "rgb(242, 218, 58)"],
                                [0.7069, "rgb(229, 217, 57)"],
                                [0.7278, "rgb(217, 216, 56)"],
                                [0.7488, "rgb(204, 216, 56)"],
                                [0.7697, "rgb(191, 215, 56)"],
                                [0.7906, "rgb(178, 214, 55)"],
                                [0.8116, "rgb(165, 213, 54)"],
                                [0.8325, "rgb(152, 212, 54)"],
                                [0.8534, "rgb(140, 211, 54)"],
                                [0.8744, "rgb(127, 210, 53)"],
                                [0.8953, "rgb(114, 209, 52)"],
                                [0.9163, "rgb(101, 208, 52)"],
                                [0.9372, "rgb(88, 208, 52)"],
                                [0.9581, "rgb(76, 207, 51)"],
                                [0.9791, "rgb(63, 206, 50)"],
                                [1.0000, "rgb(50, 205, 50)"]
                            ]
                        }
                    },
                    pointer: {
                        icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
                        length: '70%',
                        width: 12,
                        offsetCenter: [0, '10%'],
                        itemStyle: {
                            color: getColor('text-primary')
                        }
                    },
                    axisLabel: {
                        distance: 22,
                        fontSize: 10,
                        color: getColor('text-secondary')
                    },
                    detail: {
                        formatter: '{value}%',
                        valueAnimation: true,
                        fontSize: 32,
                        offsetCenter: [0, '75%'],
                        color: getColor('text-primary')
                    },
                    title: {
                        fontSize: 14,
                        offsetCenter: [0, '50%'],
                        color: getColor('text-secondary')
                    },
                    data: [{
                        name: props.name,
                        value: Math.round(1000 * props.value.last_oracle_indicator.confidence) / 10,
                    }]
                }]
            }}
        />
    );
}


export default BarometerChart;