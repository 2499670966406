import { Title, Subtitle } from '../components/title.js';


function SiteHealthPage() {
    return (
        <>
            <div className="flex justify-between items-center ">
                <Title>Site Health <sup><small>not implemeted</small></sup></Title>
                <span className="text-slate-600 dark:text-slate-400">
                    Not Updated    
                </span>
            </div>
            <Subtitle>Check the health of the site.</Subtitle>

            <p className='mt-16'>
                <strong>API URL: </strong> {process.env.REACT_APP_API_LINK}
            </p>

            {/* <div className="flex items-center bg-green-200 dark:bg-green-500 dark:bg-opacity-25 px-4 py-2 rounded-lg mt-10">
                <div className='flex items-center gap-4'>
                    <div className="w-4 h-4 bg-green-500 rounded-full"></div>
                    <div className="text-lg font-display text-green-600 dark:text-green-500">All systems operational</div>
                </div>
            </div>

            <h2 className="flex gap-2 items-center text-lg font-display font-medium text-slate-700 dark:text-slate-200 mt-12 mb-4">
                Components
                <span className="text-xs text-slate-500 dark:text-slate-500">
                    (last 90 days)
                </span>
            </h2>

            <div className="grid grid-cols-1 gap-4">
                <div className="grid grid-cols-[auto_auto_100px] gap-8 items-center bg-white dark:bg-slate-500 dark:bg-opacity-10 px-4 py-2 rounded-lg">
                    <div className='flex items-center gap-4'>
                        <div className="w-4 h-4 bg-green-500 rounded-full"></div>
                        <div className="text-lg font-display text-green-500">API</div>
                    </div>
                    <div className="flex items-center gap-1 justify-self-end">
                        {Array(90).fill().map((_, i) => (
                            <div key={i} className="w-1 h-5 bg-green-500 rounded-full"></div>
                        ))}
                    </div>
                    <div className="text-right text-sm text-green-500">Operational</div>
                </div>
                <div className="grid grid-cols-[auto_auto_100px] gap-8 items-center bg-white dark:bg-slate-500 dark:bg-opacity-10 px-4 py-2 rounded-lg">
                    <div className='flex items-center gap-4'>
                        <div className="w-4 h-4 bg-red-500 rounded-full"></div>
                        <div className="text-lg font-display text-red-500">Scraper</div>
                    </div>
                    <div className="flex items-center gap-1 justify-self-end">
                        {Array(70).fill().map((_, i) => (
                            <div key={i} className="w-1 h-5 bg-green-500 rounded-full"></div>
                        ))}
                        {Array(20).fill().map((_, i) => (
                            <div key={i} className="w-1 h-5 bg-red-500 rounded-full"></div>
                        ))}
                    </div>
                    <div className="text-right text-sm text-red-500">Down</div>
                </div>
                <div className="grid grid-cols-[auto_auto_100px] gap-8 items-center bg-white dark:bg-slate-500 dark:bg-opacity-10 px-4 py-2 rounded-lg">
                    <div className='flex items-center gap-4'>
                        <div className="w-4 h-4 bg-amber-500 rounded-full"></div>
                        <div className="text-lg font-display text-amber-500">Database</div>
                    </div>
                    <div className="flex items-center gap-1 justify-self-end">
                        {Array(70).fill().map((_, i) => (
                            <div key={i} className="w-1 h-5 bg-green-500 rounded-full"></div>
                        ))}
                        {Array(15).fill().map((_, i) => (
                            <div key={i} className="w-1 h-5 bg-red-500 rounded-full"></div>
                        ))}
                        {Array(5).fill().map((_, i) => (
                            <div key={i} className="w-1 h-5 bg-amber-500 rounded-full"></div>
                        ))}
                    </div>
                    <div className="text-right text-sm text-amber-500">Degraded</div>
                </div>
                <div className="grid grid-cols-[auto_auto_100px] gap-8 items-center bg-white dark:bg-slate-500 dark:bg-opacity-10 px-4 py-2 rounded-lg">
                    <div className='flex items-center gap-4'>
                        <div className="w-4 h-4 bg-green-500 rounded-full"></div>
                        <div className="text-lg font-display text-green-500">Cache</div>
                    </div>
                    <div className="flex items-center gap-1 justify-self-end">
                        {Array(90).fill().map((_, i) => (
                            <div key={i} className="w-1 h-5 bg-green-500 rounded-full"></div>
                        ))}
                    </div>
                    <div className="text-right text-sm text-green-500">Operational</div>
                </div>
            </div>

            <h2 className="flex gap-2 items-center text-lg font-display font-medium text-slate-700 dark:text-slate-200 mt-12 mb-4">
                Metrics
                <span className="text-xs text-slate-500 dark:text-slate-500">
                    (last 90 days)
                </span>
            </h2>

            <div className="grid grid-cols-1 gap-4">
                <div className="grid grid-cols-[auto_auto_100px] gap-8 items-center bg-white dark:bg-slate-500 dark:bg-opacity-10 px-4 py-2 rounded-lg">
                    <div className='flex items-center gap-4'>
                        <div className="w-4 h-4 bg-green-500 rounded-full"></div>
                        <div className="text-lg font-display text-green-500">API</div>
                    </div>
                    <div className="flex items-center gap-1 justify-self-end">
                        {Array(90).fill().map((_, i) => (
                            <div key={i} className="w-1 h-5 bg-green-500 rounded-full"></div>
                        ))}
                    </div>
                    <div className="text-right text-sm text-green-500">Operational</div>
                </div>
            </div>

            <h2 className="flex gap-2 items-center text-lg font-display font-medium text-slate-700 dark:text-slate-200 mt-12 mb-4">
                Incidents
                <span className="text-xs text-slate-500 dark:text-slate-500">
                    (last 90 days)
                </span>
            </h2>

            <div className="flex flex-col gap-4 bg-slate-200 dark:bg-slate-500 dark:bg-opacity-10 rounded-lg px-4 py-2">
                <p className="w-full text-center my-8 text-slate-600 dark:text-slate-300">
                    No incidents reported.
                </p>
            </div> */}

        </>
    );
}

export default SiteHealthPage;