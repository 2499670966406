import ReactEChartsCore from 'echarts-for-react/lib/core';
import * as echarts from 'echarts/core';
import { PieChart } from 'echarts/charts';
import { LabelLayout } from 'echarts/features';
import { CanvasRenderer } from 'echarts/renderers';

import { getColor } from '../../utils/colors';

// Register the required components
echarts.use(
    [PieChart, CanvasRenderer, LabelLayout]
);


function BreakdownChartPlaceholder(props) {
    return (
        <div className="opacity-50 pointer-events-none">
            <ReactEChartsCore
                className="animate-pulse"
                echarts={echarts}
                option={{
                    series: [{
                        animation: false,
                        name: 'Breakdown',
                        type: 'pie',
                        radius: ['45%', '85%'],
                        color: [
                            getColor('neutral-softDisabledColor')
                        ],
                        legend: {
                            show: false
                        },
                        itemStyle: {
                            borderRadius: 10,
                            borderColor: getColor('background-surface'),
                            borderWidth: 5,
                        },
                        label: {
                            show: false
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            { value: 33, name: 'Green Rates' },
                            { value: 33, name: 'Neutral Rates' },
                            { value: 33, name: 'Red Rates' }
                        ]
                    }]
                }} />
        </div>
    );
}


function BreakdownChart(props) {
    // Show loading indicator if data is not available
    if (props.value.last_oracle_indicator === undefined) {
        return (<BreakdownChartPlaceholder />);
    }

    // Show chart if data is available
    return (
        <ReactEChartsCore
            echarts={echarts}
            option={{
                series: [{
                    name: 'Breakdown',
                    type: 'pie',
                    radius: ['40%', '85%'],
                    tooltip: {
                        trigger: 'item'
                    },
                    color: [
                        'rgb(50, 205, 50)',
                        'rgb(255, 219, 58)',
                        'rgb(229, 64, 94)'
                    ],
                    legend: {
                        show: false
                    },
                    itemStyle: {
                        borderRadius: 10,
                        borderColor: getColor('background-surface'),
                        borderWidth: 4
                    },
                    label: {
                        show: true,
                        formatter: '{d}%',
                        position: 'inside',
                        fontSize: 12,
                        fontWeight: 'bold',
                        color: getColor('neutral-900')
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        { value: props.value.last_oracle_indicator.green_rates_count, name: 'Green Rates' },
                        { value: props.value.last_oracle_indicator.neutral_rates_count, name: 'Neutral Rates' },
                        { value: props.value.last_oracle_indicator.red_rates_count, name: 'Red Rates' }
                    ]
                }]
            }} />
    );
}

export default BreakdownChart;