/**
 * Get a color from the CSS variables
 * @param {string} color The color name
 * @returns {string} The color value "var(--color-'color')""
 * @example
 * const color = getColor('primary');
 * // color = 'rgb(0, 0, 0)'
*/
export const getColor = (color) => {
    const computedStyles = getComputedStyle(document.body);
    return computedStyles.getPropertyValue(`--joy-palette-${color}`)
};

