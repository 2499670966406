import React from "react";
import { Link, useLocation } from "react-router-dom";

import Stack from '@mui/joy/Stack';
import Button from '@mui/joy/Button';
import Typography from '@mui/joy/Typography';
import Sheet from "@mui/joy/Sheet";


class SideMenu extends React.Component {
    static Item = SideMenuItem;
    static Header = SideMenuHeader;

    render() {
        return (
            <Stack
                direction="column"
                gap="0.25rem"
                position="sticky"
                sx={{
                    width: '25rem',
                    height: '100vh',
                    maxHeight: '100svh',
                    padding: '1.5rem',
                    top: '0',
                    left: '0',
                    backgroundColor: 'background.level1',
                }}
            >
                {this.props.children}
            </Stack>
        );
    }
}


function SideMenuItem(props) {
    const location = useLocation();
    const active = location.pathname === props.to;

    return (
        <Link to={props.to}>
            <Button
                variant={active ? 'soft' : 'plain'}
                color={active ? 'primary' : 'neutral'}
                startDecorator={
                    <Sheet sx={{ backgroundColor: 'transparent', color: active ? 'primary.softColor' : 'neutral.solidBg' }}>
                        {props.icon}
                    </Sheet>
                }
                endDecorator={props.endDecorator}
                sx={{
                    justifyContent: 'flex-start',
                    gap: '0.5rem',
                    width: '100%',
                    padding: '0.5rem 1.25rem',
                    fontSize: '1rem',
                    fontWeight: '500',
                    textAlign: 'left',
                }}
            >
                <div style={{ width: "100%" }}>{props.text}</div>
                {location.pathname === props.to && <span className="sr-only">(current)</span>}
            </Button>
        </Link>
    );
}


function SideMenuHeader(props) {
    return (
        <Typography
            variant="body2"
            sx={{
                color: 'neutral.500',
                textTransform: 'uppercase',
                fontWeight: 'bold',
                fontSize: '0.75rem',
                width: '100%',
                padding: '0.5rem 1.25rem',
                marginTop: '1rem',
            }}
        >
            {props.text}
        </Typography>
    );
}


export default SideMenu;
