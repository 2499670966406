import { useState } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HiHome, HiSparkles, HiShieldCheck, HiCog6Tooth } from "react-icons/hi2";

import { CssVarsProvider, extendTheme } from '@mui/joy/styles';
import Stack from '@mui/joy/Stack';
import Sheet from '@mui/joy/Sheet';
import Typography from "@mui/joy/Typography";
import Box from "@mui/joy/Box";
import Chip from "@mui/joy/Chip";

import SideMenu from "./components/side-menu";
import DashboardPage from "./pages/dashboard";
import OraclePage from "./pages/oracle";
import SiteHealthPage from "./pages/site-health";
import SettingsPage from "./pages/settings";


const OracleTheme = extendTheme({
    fontFamily: {
        display: 'Poppins, var(--joy-fontFamily-fallback)',
        body: 'Inter, var(--joy-fontFamily-fallback)',
    },
    colorSchemes: {
        dark: {
            palette: {
                background: {
                    level1: '#09090d',
                    body: 'var(--joy-palette-neutral-900, #131318)',
                },
            },
        },
        light: {
            palette: {
                background: {
                    level1: 'var(--joy-palette-common-white, #FFF)',
                    body: 'var(--joy-palette-neutral-50, #F7F7F8)',
                },
            },
        },
    },

});

function App() {
    const [settings, setSettings] = useState({
        theme: 'system',
    });

    return (
        <CssVarsProvider theme={OracleTheme} defaultMode={settings.theme}>
            <Router>
                <Stack
                    direction="row"
                    gap={0}
                    sx={{
                        minHeight: '100svh'
                    }}
                >
                    <SideMenu>
                        <Stack direction="row" alignItems="center" gap={1.5} padding="1rem 0.5rem">
                            <Box sx={{
                                height: '2rem',
                                width: '2rem',
                                backgroundColor: 'primary.500',
                                borderRadius: '0.75rem',
                            }} />
                            <Typography level="h2" fontSize='xl'>
                                The Oracle
                            </Typography>
                        </Stack>


                        <SideMenu.Header text="General" />
                        <SideMenu.Item
                            to="/"
                            text="Dashboard"
                            icon={<HiHome className="w-5 h-5" />} />
                        <SideMenu.Item
                            to="/oracle"
                            text="Oracle Indicator"
                            icon={<HiSparkles className="w-5 h-5" />}
                            endDecorator={<Chip color="danger" variant="soft" size="sm">beta</Chip>}
                        />

                        <SideMenu.Header text="Technical" />
                        <SideMenu.Item
                            to="/site-health"
                            text="Site Health"
                            icon={<HiShieldCheck className="w-5 h-5" />}
                            endDecorator={<Chip color="danger" variant="soft" size="sm">beta</Chip>}
                        />
                        <SideMenu.Item
                            to="/settings"
                            text="Settings"
                            icon={<HiCog6Tooth className="w-5 h-5" />} />
                    </SideMenu>
                    <Sheet
                        sx={{
                            width: '100%',
                            minHeight: '100svh',
                            padding: '2rem 3rem',
                            backgroundColor: 'background.body'
                        }}
                    >
                        <Sheet
                            sx={{
                                width: '100%',
                                maxWidth: '1200px',
                                margin: '0 auto',
                                backgroundColor: 'transparent'
                            }}
                        >
                            <Routes>
                                <Route path="/" element={<DashboardPage />} />
                                <Route path="/oracle" element={<OraclePage />} />
                                <Route path="/site-health" element={<SiteHealthPage />} />
                                <Route path="/settings" element={<SettingsPage settings={settings} setSettings={setSettings} />} />
                            </Routes>
                        </Sheet>
                    </Sheet>
                </Stack>
            </Router>
        </CssVarsProvider>
    );
}

export default App;
