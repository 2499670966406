import Typography from "@mui/joy/Typography";


function Title(props) {
    return (
        <Typography level="h1" fontSize='xl3' sx={{ fontWeight: 'bold' }}>
            {props.children}
        </Typography>
    );
}

function Subtitle(props) {
    return (
        <p>{props.children}</p>
    );
}

export { Title, Subtitle };
export default Title;