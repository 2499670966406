import { useColorScheme } from '@mui/joy/styles';
import Grid from '@mui/joy/Grid';
import Typography from '@mui/joy/Typography';

import { Title, Subtitle } from '../components/title.js';
import ButtonGroup from '../components/button-group.js';


function SettingsPage(props) {
    const { mode, setMode } = useColorScheme();

    const themes = [
        { name: 'Light', value: 'light' },
        { name: 'Dark', value: 'dark' },
        { name: 'System Default', value: 'system'}
    ];

    return (
        <>
            <Title>Settings</Title>
            <Subtitle>Manage your account settings and preferences.</Subtitle>

            <Grid container spacing={3} sx={{ marginTop: '2rem' }}>
                <Grid item xs={12} md={6}>
                    <Typography level="h2" fontSize='xl'>
                        Theme
                    </Typography>
                    <Typography level="p" fontSize='sm' sx={{ color: 'text.secondary' }}>
                        Choose a theme
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <ButtonGroup
                        options={themes}
                        selectedOption={props.settings.theme}
                        onChange={(theme) => {
                            props.setSettings({
                                ...props.settings,
                                theme: theme.value
                            });
                            setMode(theme.value);
                        }}/>
                </Grid>
            </Grid>
            
            <div className="grid grid-cols-1 xl:grid-cols-[300px_1fr] gap-6 mt-10">
                
                {/* <div className="flex flex-col">
                    <div className="text-lg font-display font-medium text-slate-800 dark:text-slate-400">Theme</div>
                    <p className="text-sm text-slate-600 dark:text-slate-600 mt-0.5">Choose a theme</p>
                </div>
                <div className="flex gap-2 items-start">
                    {
                        themes.map(theme => (
                            <button
                                key={theme.value}
                                className={
                                    'font-bold font-display text-sm rounded-md px-4 py-2 ' +
                                    (theme.value === props.settings.theme ? 'bg-indigo-500 text-white' : 'bg-slate-200 dark:bg-slate-800 dark:text-slate-300 text-slate-800 dark:text-slate-200')
                                }
                                onClick={() => props.setSettings(
                                    { ...props.settings, theme: theme.value }
                                )}
                            >
                                {theme.name}
                            </button>
                        ))
                    }
                </div>
                <div className='flex gap-2'>
                    <Button>Click</Button>
                    <Button solid>Click</Button>
                </div> */}

                {/* <div className="col-span-2 border-b border-slate-200 dark:border-slate-800"></div>

                <div className="flex flex-col">
                    <div className="text-lg font-display font-medium text-slate-800 dark:text-slate-400">Language</div>
                    <p className="text-sm text-slate-600 dark:text-slate-600 mt-1">Choose a language</p>
                </div>
                <div className="flex gap-2 items-start">
                    <button className="bg-slate-200 dark:bg-slate-800 dark:text-slate-300 text-slate-800 dark:text-slate-200 font-bold font-display text-sm rounded-md px-4 py-2">English</button>
                    <button className="bg-slate-200 dark:bg-slate-800 dark:text-slate-300 text-slate-800 dark:text-slate-200 font-bold font-display text-sm rounded-md px-4 py-2">Spanish</button>
                    <button className="bg-slate-200 dark:bg-slate-800 dark:text-slate-300 text-slate-800 dark:text-slate-200 font-bold font-display text-sm rounded-md px-4 py-2">French</button>
                </div>

                <div className="col-span-2 border-b border-slate-200 dark:border-slate-800"></div>

                <div className="flex flex-col">
                    <div className="text-lg font-display font-medium text-slate-800 dark:text-slate-400">Currency</div>
                    <p className="text-sm text-slate-600 dark:text-slate-600 mt-1">Choose a currency</p>
                </div>
                <div className="flex gap-2 items-start">
                    <button className="bg-slate-200 dark:bg-slate-800 dark:text-slate-300 text-slate-800 dark:text-slate-200 font-bold font-display text-sm rounded-md px-4 py-2">USD</button>
                    <button className="bg-slate-200 dark:bg-slate-800 dark:text-slate-300 text-slate-800 dark:text-slate-200 font-bold font-display text-sm rounded-md px-4 py-2">EUR</button>
                    <button className="bg-slate-200 dark:bg-slate-800 dark:text-slate-300 text-slate-800 dark:text-slate-200 font-bold font-display text-sm rounded-md px-4 py-2">GBP</button>
                </div>

                <div className="col-span-2 border-b border-slate-200 dark:border-slate-800"></div>

                <div className="flex flex-col">
                    <div className="text-lg font-display font-medium text-slate-800 dark:text-slate-400">Timezone</div>
                    <p className="text-sm text-slate-600 dark:text-slate-600 mt-1">Choose a timezone</p>
                </div>

                <div className="flex gap-2 items-start">
                    <button className="bg-slate-200 dark:bg-slate-800 dark:text-slate-300 text-slate-800 dark:text-slate-200 font-bold font-display text-sm rounded-md px-4 py-2">UTC</button>
                    <button className="bg-slate-200 dark:bg-slate-800 dark:text-slate-300 text-slate-800 dark:text-slate-200 font-bold font-display text-sm rounded-md px-4 py-2">GMT</button>
                    <button className="bg-slate-200 dark:bg-slate-800 dark:text-slate-300 text-slate-800 dark:text-slate-200 font-bold font-display text-sm rounded-md px-4 py-2">EST</button>
                </div>

                <div className="col-span-2 border-b border-slate-200 dark:border-slate-800"></div> */}


            </div>

        </>
    );
}

export default SettingsPage;