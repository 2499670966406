import React, { useState, useEffect, useCallback } from 'react';

import { Title, Subtitle } from '../components/title.js';
import Card from '@mui/joy/Card';


// function FundingRateTable(props) {
//     // Check if data is loaded
//     if (!props.data || !props.data.coins)
//         return <div>Loading...</div>;

//     // Get columns from JSON data
//     const columns = [];
    
//     for (let coin of props.data.coins)
//         for (let exchange of Object.keys(coin.exchanges))
//             if (!columns.includes(exchange))
//                 columns.push(exchange);

//     return (
//         <table className="w-full">
//             <thead>
//                 <tr>
//                     <th className="text-left">Symbol</th>
//                     {columns.map((column, index) => (
//                         <th key={index} className="text-left">{column}</th>
//                     ))}
//                 </tr>
//             </thead>
//             <tbody>
//                 {props.data.coins.map((coin, index) => (
//                     <tr key={index}>
//                         <td>{coin.symbol}</td>
//                         {columns.map((column, index) => {
//                             if (coin.exchanges[column])
//                                 return <td key={index}>{coin.exchanges[column]}</td>
//                             else
//                                 return <td key={index}></td>
//                         })}
//                     </tr>
//                 ))}
//             </tbody>
//         </table>
            
//     );
// }


function OraclePage() {
    // Set loading state
    const [loading, setLoading] = useState(false);

    // Function to fetch data from API
    const getData = useCallback(() => {
        setLoading(true);
        fetch(`${process.env.REACT_APP_API_LINK}/funding_rate`)
            .then(res => res.json())
            .then(data => setData(data))
            .then(() => setLoading(false));
    }, []);

    // Fetch data from API
    const [data, setData] = useState({});
    useEffect(getData, [getData]);

    // Check if loading
    if (loading)
        return <div>Loading...</div>;

    return (
        <>
            <Title>Oracle Indicator <sup><small>not implemeted</small></sup></Title>
            <Subtitle>The Oracle Indicator is a tool that helps you determine if the market is bullish or bearish.</Subtitle>

            {/* <div className="grid gap-4 mt-10">
                <Card>
                    <FundingRateTable data={data} />
                </Card>
            </div> */}

        </>
    );
}

export default OraclePage;